import { Provider } from 'react-redux';
import { RelayEnvironmentProvider } from 'relay-hooks';
import { QueryClientProvider } from '@tanstack/react-query';
import ThemeProvider from '@oncore/ui/Theme';
import { EnvironmentProvider, SnackbarProvider, useEnvironment } from '@oncore/shared';
import environment from './environment';
import store from './state/store';
import OncoreFlexRouter from './components/OncoreFlexRouter';
import GlobalStyles from './spike/GlobalStyles';
import { queryClient } from './clients';
import useLegacyStateTransition from './hooks/useLegacyStateTransition';
import Loader from 'src/spike/Loader';
import OncoreNotification from 'src/spike/OncoreNotification';
import { LoggingProvider } from '@oncore/ui/shared/Logging';
import OncoreFlexThemeProvider from './components/chrome/OncoreFlexThemeProvider';
import AuthProvider from './components/Auth';
import ConnectedAppTitle from './spike/AppTitle/ConnectedAppTitle';
import { SettingsProvider } from './contexts/SettingsContext';
import OncoreLoader from '@oncore/ui/shared/OncoreLoader';

export type Props = {
  children?: never;
};

export const App = (_: Props) => {

  useLegacyStateTransition();

  const { seq } = useEnvironment();

  return (
    <LoggingProvider
      serverUrl={seq?.url}
      apiKey={seq?.key}
      redirectError
    >
      <Loader>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <SnackbarProvider>
              <OncoreNotification>
                <RelayEnvironmentProvider environment={environment}>
                  <ConnectedAppTitle>
                    <GlobalStyles />
                    <OncoreFlexRouter />
                  </ConnectedAppTitle>
                </RelayEnvironmentProvider>
              </OncoreNotification>
            </SnackbarProvider>
          </AuthProvider>
        </QueryClientProvider>
      </Loader>
    </LoggingProvider>
  );
};

const AppWithProvider = (_: Props) => {

  const handleRenderSplashScreen = () => (
    <OncoreLoader 
      variant="splash"
      loadingText="Loading environment settings..."/>
  );
 
  return (
    <Provider store={store}>
      <ThemeProvider>
        <EnvironmentProvider 
          renderSplashScreen={handleRenderSplashScreen}>
          <SettingsProvider>
            <OncoreFlexThemeProvider>
              <App/>
            </OncoreFlexThemeProvider>
          </SettingsProvider>
        </EnvironmentProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default AppWithProvider;
