import { j as t } from "../../jsx-runtime-B6kdoens.js";
import { useEffect as I, useState as C, useMemo as T } from "react";
import r, { keyframes as w } from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-C9VCECeM.js";
import { getSpacings as o, getPaletteBorder as A, getRadii as E, getPaletteBackground as P, getEffects as D, getBreakpointMediaQuery as g, getZIndexes as _ } from "../Theme/utils.es.js";
import "../../CssBaseline-CQMfs2gH.js";
import { I as k } from "../../IconButton-D51uKWnW.js";
import { I as z } from "../../Icon-C3nZvRjn.js";
import "../Icon/countryVariants.es.js";
import { F as S } from "../../FileIcon-BhdKUMEc.js";
import "../FeaturedIcon/constants.es.js";
import "../../styles-DFJgZZAt.js";
import { T as b } from "../../Typography-GJu-MHyZ.js";
import { notificationItemAnimationKeyframes as B, notificationListStyles as M } from "./styles.es.js";
import { STATE_VARIANT_MAPPER as R } from "./constants.es.js";
import { N as F } from "../../hooks-DySLQ8xI.js";
import { u as gt } from "../../hooks-DySLQ8xI.js";
const L = r.div`
  display: flex;
  position: relative;
  width: 400px;
  box-sizing: border-box;
  
  animation-timing-function: ease-in-out;
  animation-duration: 300ms;
  
  animation-name: ${(i) => B[i.$position]};
  
  padding: ${o("xl")};
  border: 1px solid ${A("primary")};
  border-radius: ${E("xl")};
  background: ${P("primary.default")};
  box-shadow: ${D("shadows.lg")};
  
  ${g("max", "lg")} {
    width: 100%;
  }
`, q = r.div`
  display: flex;
  flex-direction: row;
  
  padding-right: ${o("4xl")};
  gap: ${o("xl")};
  
  ${g("max", "lg")} {
    flex-direction: column;
  }
`, O = r.div`
  display: flex;
  flex-direction: column;
  
  gap: ${o("lg")};
`, U = r.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  
  gap: ${o("xs")};
`, H = r(k)`
  position: absolute;
  top: 8px;
  right: 8px;
`, K = (i) => {
  const {
    title: l,
    message: a,
    variant: n,
    className: f,
    position: d = "bottom-left",
    children: m,
    autoCloseDuration: s,
    onClose: c
  } = i;
  return I(() => {
    let p;
    return c && s && (p = setTimeout(() => {
      c();
    }, s)), () => {
      clearTimeout(p);
    };
  }, []), /* @__PURE__ */ t.jsxs(
    L,
    {
      $position: d,
      className: f,
      children: [
        /* @__PURE__ */ t.jsx(
          H,
          {
            variant: "ghost",
            color: "gray",
            onClick: c,
            children: /* @__PURE__ */ t.jsx(
              z,
              {
                variant: "close"
              }
            )
          }
        ),
        /* @__PURE__ */ t.jsxs(q, { children: [
          n && /* @__PURE__ */ t.jsx(
            S,
            {
              size: "sm",
              variant: "outlined",
              color: n,
              iconProps: {
                variant: R[n]
              }
            }
          ),
          /* @__PURE__ */ t.jsxs(O, { children: [
            /* @__PURE__ */ t.jsxs(U, { children: [
              /* @__PURE__ */ t.jsx(
                b,
                {
                  size: "sm",
                  weight: "semibold",
                  children: l
                }
              ),
              a && /* @__PURE__ */ t.jsx(
                b,
                {
                  size: "sm",
                  weight: "regular",
                  children: a
                }
              )
            ] }),
            m
          ] })
        ] })
      ]
    }
  );
}, Q = w`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
`, V = r.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  position: fixed;
  
  animation: ease-in-out ${Q} 150ms;
  
  gap: ${o("3xl")};
  padding: ${o("3xl")};
  z-index: ${_("notification")};
  
  ${g("max", "lg")} {
    width: 100%;
    background: linear-gradient(357deg, rgba(0, 0, 0, 0.10) 2.75%, rgba(0, 0, 0, 0.00) 92.77%);
    margin: 0;

    gap: ${o("xl")};
    padding-top: ${o("xl")};
    padding-bottom: ${o("xl")};
    
    padding-left: ${o("md")};
    padding-right: ${o("md")};
  };
  
  ${(i) => M[i.$position]};
`, Z = (i) => {
  const {
    children: l,
    className: a,
    position: n = "bottom-left"
  } = i;
  return /* @__PURE__ */ t.jsx(
    V,
    {
      $position: n,
      className: a,
      children: l
    }
  );
}, G = 8e3, ft = (i) => {
  const {
    children: l,
    className: a,
    position: n = "bottom-left",
    autoHideDuration: f = G
  } = i, [
    d,
    m
  ] = C({}), s = T(() => Object.values(d), [d]), c = (e) => {
    const {
      onClose: h,
      ...y
    } = e, x = Date.now(), N = (u) => {
      m((j) => {
        const $ = {
          ...j
        };
        return delete $[u], $;
      });
    }, v = () => {
      h && h(), N(x);
    };
    m((u) => ({
      ...u,
      [x]: {
        autoCloseDuration: f,
        ...y,
        id: x,
        onClose: v
      }
    }));
  }, p = () => {
    s.forEach(({ onClose: e }) => {
      e && e();
    });
  };
  return /* @__PURE__ */ t.jsxs(
    F.Provider,
    {
      value: {
        enqueueNotification: c,
        removeAllNotifications: p
      },
      children: [
        l,
        s.length > 0 && /* @__PURE__ */ t.jsx(
          Z,
          {
            className: a,
            position: n,
            children: s.map((e) => /* @__PURE__ */ t.jsx(
              K,
              {
                ...e,
                position: n
              },
              e.id
            ))
          }
        )
      ]
    }
  );
};
export {
  ft as default,
  gt as useNotification
};
