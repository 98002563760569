import { createContext as t, useContext as o } from "react";
const e = t({
  enqueueNotification: () => {
  },
  removeAllNotifications: () => {
  }
}), n = () => o(e);
export {
  e as N,
  n as u
};
